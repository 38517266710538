import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ScreenShotImg from "../images/screenshot-1.png"
import FbImage from "../images/og-image.jpg"
import WorkingComputer from "../images/working-on-computer.jpeg"

const metrics = [
  {
    id: 1,
    stat: "Simple",
    text: "Simple resource planning at the click of a button",
  },
  {
    id: 2,
    stat: "Visual",
    text: "A strategic communications planning tool for everyone",
  },
  {
    id: 3,
    stat: "Real-Time",
    text: "A live work in progress to manage projects and to do lists",
  },
  {
    id: 4,
    stat: "Collaborative",
    text: "A place to store critical client or stakeholder details",
  },
]

const features = [
  {
    name: "Strategic Planner",
    description:
      "Simplify your strategic communications planning with our step by step guide. Once you’ve drafted the strategy you can revisit and use it as a basis for the work-plan.",
    icon: props => (
      <svg viewBox="0 0 384 512" fill="currentColor" {...props}>
        <path d="M360 464H23.1C10.75 464 0 474.7 0 487.1S10.75 512 23.1 512H360C373.3 512 384 501.3 384 488S373.3 464 360 464zM345.1 32h-308C17 32 0 49 0 70v139.4C0 218.8 4 227.5 11 233.6L48 265.8c0 8.885 .0504 17.64 .0504 26.46c0 39.32-1.001 79.96-11.93 139.8h49C94.95 374.3 96.11 333.3 96.11 285.5C96.11 270.7 96 255.1 96 238.2L48 196.5V80h64V128H160V80h64V128h48V80h64v116.5L288 238.2c0 16.77-.1124 32.25-.1124 47.1c0 47.79 1.164 89.15 10.99 146.7h49c-10.92-59.83-11.93-100.6-11.93-139.9C335.9 283.3 336 274.6 336 265.8l37-32.13C380 227.5 384 218.8 384 209.4V70C384 49 367 32 345.1 32zM192 224C174.4 224 160 238.4 160 256v64h64V256C224 238.4 209.6 224 192 224z" />
      </svg>
    ),
  },
  {
    name: "Resource Planner",
    description:
      "Whether an in-house team or an agency, you can allocate project time to everyone and identify who has capacity and who has too much on their plate.\n",
    icon: props => (
      <svg viewBox="0 0 24 24" fill="currentColor" {...props}>
        <path d="M20.9984 6.24971C20.9984 4.45495 19.5435 3 17.7487 3H6.24971C4.45495 3 3 4.45495 3 6.24971V17.7487C3 19.5435 4.45495 20.9984 6.24971 20.9984H11.1644L11.5202 19.5754C11.5266 19.5497 11.5333 19.5241 11.5402 19.4985H6.24971C5.2833 19.4985 4.49987 18.7151 4.49987 17.7487V8.49952H19.4985V11.2317C19.9774 11.0412 20.492 10.9678 20.9984 11.0115V6.24971ZM6.24971 4.49987H17.7487C18.7151 4.49987 19.4985 5.2833 19.4985 6.24971V6.99965H4.49987V6.24971C4.49987 5.2833 5.2833 4.49987 6.24971 4.49987ZM19.0984 12.6686L13.1965 18.5705C12.8524 18.9146 12.6083 19.3458 12.4903 19.8179L12.0327 21.6484C11.8336 22.4445 12.5547 23.1656 13.3508 22.9666L15.1813 22.5089C15.6534 22.3909 16.0846 22.1468 16.4287 21.8027L22.3306 15.9008C23.2231 15.0082 23.2231 13.5611 22.3306 12.6686C21.4381 11.7761 19.991 11.7761 19.0984 12.6686Z" />
      </svg>
    ),
  },
  {
    name: "WIP",
    description:
      "The searchable and filterable work in progress planner lets everyone share their activities. Project managers can use the WIP to keep on top of actions.",
    icon: props => (
      <svg viewBox="0 0 20 20" fill="currentColor" {...props}>
        <path d="M5.85355 4.35355C6.04882 4.15829 6.04882 3.84171 5.85355 3.64645C5.65829 3.45118 5.34171 3.45118 5.14645 3.64645L3.5 5.29289L2.85355 4.64645C2.65829 4.45118 2.34171 4.45118 2.14645 4.64645C1.95118 4.84171 1.95118 5.15829 2.14645 5.35355L3.14645 6.35355C3.34171 6.54882 3.65829 6.54882 3.85355 6.35355L5.85355 4.35355ZM8.75 4.5C8.33579 4.5 8 4.83579 8 5.25C8 5.66421 8.33579 6 8.75 6H17.25C17.6642 6 18 5.66421 18 5.25C18 4.83579 17.6642 4.5 17.25 4.5H8.75ZM8.75 9.5C8.33579 9.5 8 9.83579 8 10.25C8 10.6642 8.33579 11 8.75 11H17.25C17.6642 11 18 10.6642 18 10.25C18 9.83579 17.6642 9.5 17.25 9.5H8.75ZM8 15.25C8 14.8358 8.33579 14.5 8.75 14.5H17.25C17.6642 14.5 18 14.8358 18 15.25C18 15.6642 17.6642 16 17.25 16H8.75C8.33579 16 8 15.6642 8 15.25ZM5.85355 9.85355C6.04882 9.65829 6.04882 9.34171 5.85355 9.14645C5.65829 8.95118 5.34171 8.95118 5.14645 9.14645L3.5 10.7929L2.85355 10.1464C2.65829 9.95118 2.34171 9.95118 2.14645 10.1464C1.95118 10.3417 1.95118 10.6583 2.14645 10.8536L3.14645 11.8536C3.34171 12.0488 3.65829 12.0488 3.85355 11.8536L5.85355 9.85355ZM5.85355 14.1464C6.04882 14.3417 6.04882 14.6583 5.85355 14.8536L3.85355 16.8536C3.65829 17.0488 3.34171 17.0488 3.14645 16.8536L2.14645 15.8536C1.95118 15.6583 1.95118 15.3417 2.14645 15.1464C2.34171 14.9512 2.65829 14.9512 2.85355 15.1464L3.5 15.7929L5.14645 14.1464C5.34171 13.9512 5.65829 13.9512 5.85355 14.1464Z" />
      </svg>
    ),
  },
  {
    name: "Brands",
    description:
      "The brand section allows agencies to manage the account teams for each client and also save important notes such as billing instructions.",
    icon: props => (
      <svg fill="currentColor" {...props} viewBox="0 0 24 24">
        <path d="M14.75 15C15.7165 15 16.5 15.7835 16.5 16.75L16.4989 17.7121C16.6156 19.9012 14.9879 21.009 12.0668 21.009C9.15786 21.009 7.5 19.9192 7.5 17.75V16.75C7.5 15.7835 8.2835 15 9.25 15H14.75ZM14.75 16.5H9.25C9.11193 16.5 9 16.6119 9 16.75V17.75C9 18.9262 9.88667 19.509 12.0668 19.509C14.2347 19.509 15.0625 18.9455 15 17.752V16.75C15 16.6119 14.8881 16.5 14.75 16.5ZM3.75 10L8.126 10.0001C8.04375 10.3197 8 10.6547 8 11C8 11.1691 8.01049 11.3358 8.03087 11.4993L3.75 11.5C3.61193 11.5 3.5 11.6119 3.5 11.75V12.75C3.5 13.9262 4.38667 14.509 6.56679 14.509C7.02869 14.509 7.42976 14.4835 7.77434 14.4317C7.20917 14.7897 6.78546 15.349 6.60122 16.0082L6.56679 16.009C3.65786 16.009 2 14.9192 2 12.75V11.75C2 10.7835 2.7835 10 3.75 10ZM20.25 10C21.2165 10 22 10.7835 22 11.75L21.9989 12.7121C22.1156 14.9012 20.4879 16.009 17.5668 16.009L17.3985 16.0073C17.2091 15.3302 16.767 14.7587 16.1803 14.4008C16.567 14.4733 17.0273 14.509 17.5668 14.509C19.7347 14.509 20.5625 13.9455 20.5 12.752V11.75C20.5 11.6119 20.3881 11.5 20.25 11.5L15.9692 11.4992C15.9895 11.3357 16 11.1691 16 11C16 10.6547 15.9563 10.3197 15.874 10.0001L20.25 10ZM12 8C13.6569 8 15 9.34315 15 11C15 12.6569 13.6569 14 12 14C10.3431 14 9 12.6569 9 11C9 9.34315 10.3431 8 12 8ZM12 9.5C11.1716 9.5 10.5 10.1716 10.5 11C10.5 11.8284 11.1716 12.5 12 12.5C12.8284 12.5 13.5 11.8284 13.5 11C13.5 10.1716 12.8284 9.5 12 9.5ZM6.5 3C8.15685 3 9.5 4.34315 9.5 6C9.5 7.65685 8.15685 9 6.5 9C4.84315 9 3.5 7.65685 3.5 6C3.5 4.34315 4.84315 3 6.5 3ZM17.5 3C19.1569 3 20.5 4.34315 20.5 6C20.5 7.65685 19.1569 9 17.5 9C15.8431 9 14.5 7.65685 14.5 6C14.5 4.34315 15.8431 3 17.5 3ZM6.5 4.5C5.67157 4.5 5 5.17157 5 6C5 6.82843 5.67157 7.5 6.5 7.5C7.32843 7.5 8 6.82843 8 6C8 5.17157 7.32843 4.5 6.5 4.5ZM17.5 4.5C16.6716 4.5 16 5.17157 16 6C16 6.82843 16.6716 7.5 17.5 7.5C18.3284 7.5 19 6.82843 19 6C19 5.17157 18.3284 4.5 17.5 4.5Z" />
      </svg>
    ),
  },
]

const IndexPage = () => (
  <Layout>
    <Seo title="Marketing Project Management Software" image={FbImage} />
    {/* Gradient Feature Section */}
    <div className="bg-secondary-600">
      <div className="max-w-4xl mx-auto px-4 py-16 sm:px-6 sm:pt-20 sm:pb-24 lg:max-w-7xl lg:pt-24 lg:px-8">
        <h2 className="text-3xl font-extrabold text-primary-100 tracking-tight">
          Planning Tools
        </h2>
        <p className="mt-4 max-w-3xl text-lg text-primary-50">
          A one stop shop for communications strategy development, team resource
          planning and project ‘work in progress’ management – Rhubarb Plans
          gets everyone on the same page, freeing up teams to deliver.
        </p>
        <div className="mt-12 grid grid-cols-1 gap-x-6 gap-y-12 sm:grid-cols-2 lg:mt-16 lg:grid-cols-4 lg:gap-x-8 lg:gap-y-16">
          {features.map(feature => (
            <div key={feature.name}>
              <div>
                <span className="flex items-center justify-center h-12 w-12 rounded-md bg-white bg-opacity-10">
                  <feature.icon
                    className="h-6 w-6 text-primary-50"
                    aria-hidden="true"
                  />
                </span>
              </div>
              <div className="mt-6">
                <h3 className="text-lg font-medium text-primary-50">
                  {feature.name}
                </h3>
                <p className="mt-2 text-base text-primary-50">
                  {feature.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>

    {/* Alternating Feature Sections */}
    <div className="relative pt-16 pb-32 overflow-hidden">
      <div
        aria-hidden="true"
        className="absolute inset-x-0 top-0 h-48 bg-gradient-to-b from-gray-100"
      />
      <div className="relative">
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
          <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
            <div>
              <div className="mt-6">
                <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                  Get Everyone on the Same Page{" "}
                </h2>
                <p className="mt-4 text-lg text-gray-500">
                  Most communications strategies sit in a drive and are seldom
                  looked at, and some organisations have no communications
                  strategy at all. Using Rhubarb Plans you can build a strategic
                  comms plan quickly and easily, and better still it will be on
                  your homepage to refer to day to day. Pairing the strategy
                  with the WIP allows you to ensure your tactical plans help you
                  achieve the bigger picture.
                </p>
                <div className="mt-6">
                  <a
                    href="https://app.rhubarbplans.com/auth/sign-up"
                    className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 text-white bg-secondary-500 hover:bg-secondary-700 focus:ring-secondary-700 disabled:bg-secondary-500"
                  >
                    Sign Up
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-12 sm:mt-16 lg:mt-0">
            <div className="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
              <img
                className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                src={ScreenShotImg}
                alt="Screenshot"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="mt-24">
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
          <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
            <div>
              <div className="mt-6">
                <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                  Simplified Resource Planning for Comms Teams
                </h2>
                <p className="mt-4 text-lg text-gray-500">
                  Our communications resource plan is a simple system for agency
                  and in-house teams. Start by entering how much time each
                  person has in the month, then assign that time against
                  projects. You’ll see at a glance who is free to help on the
                  next project that comes in.
                </p>
                <div className="mt-6">
                  <a
                    href="https://app.rhubarbplans.com/auth/sign-up"
                    className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 text-white bg-secondary-500 hover:bg-secondary-700 focus:ring-secondary-700 disabled:bg-secondary-500"
                  >
                    Sign Up
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
            <div className="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
              <img
                alt="App screenshot"
                className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
                src={ScreenShotImg}
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    {/* Stats section */}
    <div className="relative bg-gray-900">
      <div className="h-80 absolute inset-x-0 bottom-0 xl:top-0 xl:h-full">
        <div className="h-full w-full xl:grid xl:grid-cols-2">
          <div className="h-full xl:relative xl:col-start-2">
            <img
              alt="People working on laptops"
              className="h-full w-full object-cover opacity-25 xl:absolute xl:inset-0"
              src={WorkingComputer}
            />
            <div
              aria-hidden="true"
              className="absolute inset-x-0 top-0 h-32 bg-gradient-to-b from-gray-900 xl:inset-y-0 xl:left-0 xl:h-full xl:w-32 xl:bg-gradient-to-r"
            />
          </div>
        </div>
      </div>
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8 xl:grid xl:grid-cols-2 xl:grid-flow-col-dense xl:gap-x-8">
        <div className="relative pt-12 pb-64 sm:pt-24 sm:pb-64 xl:col-start-1 xl:pb-24">
          <p className="mt-3 text-3xl font-extrabold text-white">
            With Rhubarb Plans you can get everyone on the same page with
            communications strategy and execution.
          </p>
          <p className="mt-5 text-lg text-gray-300">
            Our simple system requires no training and can be set up in minutes.
            The strategy development asks all the right questions to agree a
            clear communications approach that meets organisational goals. The
            teaming up of a strategy development tool, a resource plan and a
            live work in progress tracker have all bases covered.
          </p>
          <div className="mt-12 grid grid-cols-1 gap-y-12 gap-x-6 sm:grid-cols-2">
            {metrics.map(item => (
              <p key={item.id}>
                <span className="block text-2xl font-bold text-white">
                  {item.stat}
                </span>
                <span className="mt-1 block text-base text-gray-300">
                  {item.text}
                </span>
              </p>
            ))}
          </div>
        </div>
      </div>
    </div>

    {/* CTA Section */}
    <div className="bg-gray-50">
      <div className="max-w-4xl mx-auto py-16 px-4 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8 lg:flex lg:items-center lg:justify-between">
        <h2 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
          <span className="block">Ready to dive in?</span>
          <span className="text-secondary-500">
            Start your free trial today.
          </span>
        </h2>
        <div className="mt-6 space-y-4 sm:space-y-0 sm:flex sm:space-x-5">
          <a
            href="https://app.rhubarbplans.com/auth/sign-up"
            className="inline-flex items-center px-6 py-4 border border-transparent text-base font-medium rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 text-white bg-secondary-500 hover:bg-secondary-700 focus:ring-secondary-700 disabled:bg-secondary-500"
          >
            Sign Up
          </a>
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
